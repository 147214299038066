<template>

            
   <van-row justify="center"  style="width:100%;height: calc(var(--vh, 1vh) * 95);overflow-y: scroll;">
        <van-col  :span="24" style="margin-top: 50%;" >
            <van-empty description="功能暂未开放，敬请期待！" />

        </van-col>
    </van-row>

        <!-- 底部    -->
   <van-row justify="center" >
        <van-col  :span="24" >
            <nav-bar />
        </van-col>
    </van-row>

</template>
<script>
    import NavBar from '@/components/NavBar'
    export default {
        name: "zwsj",
        setup(){

        },
        components: {
            NavBar,
        }
    }
</script>
